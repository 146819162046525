import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState, createContext, useContext } from 'react';
import { Toaster } from '@/components/ui/toaster';
import { AppHeader } from '@/components/AppHeader';
import { Dashboard } from '@/components/Dashboard';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { EditPost } from '@/components/EditPost';
import { Footer } from '@/components/Footer';
import { FeedbackWidget } from '@/components/FeedbackWidget';
import { supabase } from '@/integrations/supabase/client';
import { initializeAIModel } from '@/services/deepseek';
import { HelmetProvider } from 'react-helmet-async';
import Index from '@/pages/Index';
import Login from '@/pages/Login';
import Documentation from '@/pages/Documentation';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import TermsOfService from '@/pages/TermsOfService';
import { PostCreator } from '@/components/post-creator';
import { ContentPlanner } from '@/components/ContentPlanner';
import { ProfileOptimizer } from '@/components/ProfileOptimizer';
import Admin from '@/pages/Admin';
import AuthCallback from '@/pages/AuthCallback';
import BetaLandingPage from '@/pages/Beta';
import BetaInvite from '@/pages/BetaInvite';
import type { User, AuthChangeEvent, Session } from '@supabase/supabase-js';
import { CarouselCreator } from '@/features/final-carousel-creator/components/CarouselCreator';
import '@/features/final-carousel-creator/styles/globals.css';
import Pro from './pages/Pro';
import CanceledSubscription from './pages/CanceledSubscription';
import SubscriptionSuccess from './pages/SubscriptionSuccess';



interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  user: null,
});

export const useAuth = () => useContext(AuthContext);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Handle auth state changes
  const handleAuthChange = async (event: AuthChangeEvent, session: Session | null) => {
    if (!isInitialized) return;

    const newAuthState = !!session;
    const newUser = session?.user || null;

    // Only update if there's an actual change
    if (isAuthenticated !== newAuthState || user?.id !== newUser?.id) {
      setIsAuthenticated(newAuthState);
      setUser(newUser);

      if (newUser) {
        try {
          await initializeAIModel();
        } catch (error) {
          console.error('Error initializing AI model:', error);
        }
      }
    }
    
    setIsLoading(false);
  };

  // Initial setup and auth check
  useEffect(() => {
    let mounted = true;

    const initializeAuth = async () => {
      try {
        // Get initial session
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error;

        if (mounted) {
          setIsAuthenticated(!!session);
          setUser(session?.user || null);

          if (session?.user) {
            try {
              await initializeAIModel();
            } catch (error) {
              console.error('Error initializing AI model:', error);
            }
          }
        }
      } catch (error) {
        console.error('Error during auth initialization:', error);
        if (mounted) {
          setIsAuthenticated(false);
          setUser(null);
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
          setIsInitialized(true);
        }
      }
    };

    initializeAuth();

    return () => {
      mounted = false;
    };
  }, []);

  // Set up auth state listener after initialization
  useEffect(() => {
    if (!isInitialized) return;

    const { data: { subscription } } = supabase.auth.onAuthStateChange(handleAuthChange);

    return () => {
      subscription.unsubscribe();
    };
  }, [isInitialized]);

  // Show loading state
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white flex items-center justify-center">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, user }}>
      <HelmetProvider>
        <Router>
          <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white flex flex-col">
            <AppHeader />
            <div className="pt-16 flex-1">
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/login" element={<Login />} />
                <Route path="/beta" element={<BetaLandingPage />} />
                <Route path="/beta/invite" element={<BetaInvite />} />
                <Route path="/auth/callback" element={<AuthCallback />} />
                <Route path="/documentation" element={<Documentation />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route 
                  path="/admin" 
                  element={
                    <ProtectedRoute>
                      <Admin />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/dashboard" 
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/optimizer" 
                  element={
                    <ProtectedRoute>
                      <ProfileOptimizer />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/planner" 
                  element={
                    <ProtectedRoute>
                      <ContentPlanner />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/posts/new" 
                  element={
                    <ProtectedRoute>
                      <PostCreator />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/posts/:id" 
                  element={
                    <ProtectedRoute>
                      <EditPost />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/carousel" 
                  element={
                    <ProtectedRoute>
                      <CarouselCreator />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/pro" 
                  element={
                    <ProtectedRoute>
                      <Pro />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/subscription/canceled" 
                  element={
                    <ProtectedRoute>
                      <CanceledSubscription />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/subscription/success" 
                  element={
                    <ProtectedRoute>
                      <SubscriptionSuccess />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/pro/canceled" 
                  element={
                    <ProtectedRoute>
                      <CanceledSubscription />
                    </ProtectedRoute>
                  } 
                />
              </Routes>
            </div>
            <Footer />
            {isAuthenticated && <FeedbackWidget />}
            <Toaster />
          </div>
        </Router>
      </HelmetProvider>
    </AuthContext.Provider>
  );
}

export default App;