export interface AboutSection {
  aboutSection: {
    industryChallenge: string;
    currentSolutions: string;
    successVision: string;
    expertise: string[];
    credentials: string;
    callToAction: string;
  };
}

export const rewriteAboutPrompt = `Create a compelling LinkedIn About section based on the following examples and tone styles. Analyze the appropriate example based on the selected tone before creating the about section. Pay careful attention to the formatting and keep paragraphs short.

EXAMPLES:

1. CASUAL TONE EXAMPLE:
Hi I'm [name] and welcome to my profile.

I write about empathy, trust and compassion in healthcare

Mainly because these are the most important contributors for healing for both patients and clinicians.

Since qualifying as a Doctor in 2012 and subsequently was a GP, I've witnessed the beauty and the beast of healthcare in the NHS.

[...shortened for brevity but includes personal story, challenges, and solutions...]

Let me know what resonates with you. I always love to hear from you.

2. PROFESSIONAL TONE EXAMPLE:
I'm [name] a dedicated oncologist leading cancer care in [place]. I am deeply committed to advancing oncology. I'm proud to be the first Professor of [specialty] in the [place].

My training spans prestigious institutions, including a medical degree from Ireland's National University and fellowships at McMaster University in Canada. I am certified in Internal Medicine and Oncology across the UK, USA, and Canada, specializing in Gastrointestinal Oncology.

My journey in oncology is dedicated to driving advancements in cancer care across [region].

3. STORY TONE EXAMPLE:
If you had to choose, would you follow the traditional route or follow your heart?

In 2018, after ten long years of medical training, I qualified as an NHS GP. I was proud of my achievements, but I was also burned out.

[...shortened for brevity but includes personal journey, transformation, and current mission...]

I chose to follow my heart. Will you?

4. IMPACTFUL TONE EXAMPLE:
A multi-award winning surgeon, teacher, futurist, innovator and entrepreneur. 

I envision a world in which everyone can live healthy, productive lives, regardless of who they are or where they live.

After analyzing these examples, create an About section following these rules:
- No salesy language (discover, skyrocket, revolutionize, etc.)
- Natural language
- First person
- No emojis
- Not CV-style
- Total content should be under 300 words
- Keep paragraphs short and impactful
- Match the selected tone style

Return the content as a valid JSON object with the following structure. Do not include any text outside the JSON object:

{
  "aboutSection": {
    "industryChallenge": "The key problem or challenge in your industry",
    "currentSolutions": "How you approach these challenges",
    "successVision": "Your vision for success or impact",
    "expertise": [
      "Key area of expertise 1",
      "Key area of expertise 2",
      "Key area of expertise 3"
    ],
    "credentials": "Your relevant credentials and experience",
    "callToAction": "How others can engage with you"
  }
}`;

export function validateAboutSection(json: unknown): json is AboutSection {
  if (!json || typeof json !== 'object') return false;
  const analysis = json as AboutSection;
  
  return !!(
    analysis.aboutSection?.industryChallenge &&
    analysis.aboutSection?.currentSolutions &&
    analysis.aboutSection?.successVision &&
    Array.isArray(analysis.aboutSection?.expertise) &&
    analysis.aboutSection?.credentials &&
    analysis.aboutSection?.callToAction
  );
}

export function formatAboutSection(about: AboutSection): string {
  return `<div class="space-y-4">
    <div class="text-lg">
      ${about.aboutSection.industryChallenge}
    </div>

    <div>
      ${about.aboutSection.currentSolutions}
    </div>

    <div>
      ${about.aboutSection.successVision}
    </div>

    <div class="space-y-2">
      <div class="font-semibold">Key expertise:</div>
      <ul class="list-disc pl-6 space-y-1">
        ${about.aboutSection.expertise.map(e => `<li>${e}</li>`).join('\n')}
      </ul>
    </div>

    <div>
      ${about.aboutSection.credentials}
    </div>

    <div class="font-medium">
      ${about.aboutSection.callToAction}
    </div>
  </div>`;
} 