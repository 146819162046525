import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Timer, Users, Star, CheckCircle2, Linkedin, Twitter, Facebook } from 'lucide-react'; // Includes Linkedin for auth buttons
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { sendBetaApplicationEmail } from '@/integrations/supabase/email';
import type { Session } from '@supabase/supabase-js';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { BetaSpots } from '@/components/BetaSpots';
import { AuthError } from '@supabase/supabase-js';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

// --- Animation Variants ---
const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

// --- Footer Component ---
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <motion.footer
      className="bg-gray-100 border-t border-gray-200 py-8 mt-auto" // Ensure footer stays at bottom
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-6xl mx-auto px-4 text-center text-gray-500">
        <div className="flex justify-center space-x-6 mb-4">
          {/* Replace # with your actual links */}
          <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors" aria-label="LinkedIn">
            <Linkedin className="h-5 w-5" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors" aria-label="Twitter">
            <Twitter className="h-5 w-5" />
          </a>
          {/* Optional Facebook link
          <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors" aria-label="Facebook">
            <Facebook className="h-5 w-5" />
          </a> */}
        </div>
        <div className="text-sm mb-2 space-x-2"> {/* Added space-x for better spacing */}
          <a href="/privacy" className="hover:text-primary transition-colors">Privacy Policy</a>
          <span>|</span>
          <a href="/terms" className="hover:text-primary transition-colors">Terms of Service</a>
          <span>|</span>
          <a href="/contact" className="hover:text-primary transition-colors">Contact</a>
        </div>
        <p className="text-sm">© {currentYear} Medical Post. All rights reserved.</p>
      </div>
    </motion.footer>
  );
};


// --- Beta Application Form Interface ---
interface BetaApplicationForm {
  full_name: string;
  specialty: string;
  linkedin_url: string;
  linkedin_connections: number;
  why_join_beta: string;
  email: string;
  password?: string; // Added for the registration step
}

// --- Authentication Form State ---
type AuthStep = 'register' | 'login' | 'beta-details' | 'thank-you';

// --- Main Beta Landing Page Component ---
const BetaLandingPage = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userSession, setUserSession] = useState<Session | null>(null);
  const [authStep, setAuthStep] = useState<AuthStep>('register');
  const [submittedEmail, setSubmittedEmail] = useState(''); // Store submitted email for thank you screen
  const [formData, setFormData] = useState<BetaApplicationForm>({
    full_name: '',
    specialty: '',
    linkedin_url: '',
    linkedin_connections: 0,
    why_join_beta: '',
    email: '',
    password: ''
  });

  // Add state for parallax scroll effect
  const [scrollY, setScrollY] = useState(0);

  // Add state to track if email needs verification
  const [needsEmailVerification, setNeedsEmailVerification] = useState(false);

  // Effect for parallax scroll tracking
  useEffect(() => {
    const handleScroll = () => setScrollY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove listener when component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Effect for checking user session and pre-filling form data
  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        setUserSession(session);
        // Use functional updates for setting state based on previous state
        if (session?.user?.email) {
          setFormData(prev => ({ ...prev, email: session.user.email || '' }));
          // If user is already logged in, skip to beta details
          setAuthStep('beta-details');
        }
        if (session?.user?.user_metadata?.full_name) {
           setFormData(prev => ({ ...prev, full_name: session.user.user_metadata.full_name || '' }));
        }
      } catch (error) {
          console.error("Error checking session:", error);
      }
    };
    checkSession();
  }, []);

  // Effect to check session when modal opens
  useEffect(() => {
    if (showApplicationModal) {
      const checkSessionForModal = async () => {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.user) {
          setAuthStep('beta-details');
        } else {
          setAuthStep('register');
        }
      };
      checkSessionForModal();
    }
  }, [showApplicationModal]);

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    if (id === 'linkedin_connections') {
      // Convert string to number for the connections field
      setFormData({
        ...formData,
        [id]: parseInt(value) || 0
      });
    } else {
      setFormData({
        ...formData,
        [id]: value
      });
    }
  };

  // Form validation
  const validateForm = () => {
    // Only validate fields relevant to the current step
    if (authStep === 'register' || authStep === 'login') {
      if (!formData.email) return "Email is required";
      if (authStep === 'register' && (!formData.password || formData.password.length < 6)) {
        return "Password must be at least 6 characters";
      }
      return null;
    }
    
    // Beta details validation
    if (!formData.full_name) return "Full name is required";
    if (!formData.specialty) return "Primary specialty is required";
    if (!formData.linkedin_url) return "LinkedIn URL is required";
    if (!formData.linkedin_connections) return "Number of LinkedIn connections is required";
    if (!formData.why_join_beta) return "Please tell us why you want to join the beta";
    return null;
  };

  // Handle authentication
  const handleAuth = async (type: 'register' | 'login', authData: { email: string, password?: string, full_name?: string }) => {
    // Validation moved to ApplicationModal

    setIsSubmitting(true);

    try {
      // Store current auth step to prevent unwanted flashing
      const currentStep = authStep;

      if (type === 'register') {
        const { data, error } = await supabase.auth.signUp({
          email: authData.email,
          password: authData.password!,
          options: {
            data: {
              // Use full_name from authData if available, otherwise fallback to existing formData (though unlikely needed)
              full_name: authData.full_name || formData.full_name || '',
            },
            emailRedirectTo: `${window.location.origin}/`
          }
        });

        if (error) throw error;

        // Check if user already exists
        if (data?.user?.identities?.length === 0) {
          toast({
            title: "Email already registered",
            description: "Please try logging in instead.",
            variant: "destructive",
          });
          setAuthStep('login');
          setIsSubmitting(false);
          return;
        }

        // Check if email confirmation is needed
        if (data?.user && !data.user.confirmed_at) {
          setNeedsEmailVerification(true);
        }

        toast({
          title: "Account created",
          description: "Please continue with your beta application.",
        });

        // Save user info to the form state *after* successful auth
        if (data?.user?.email) {
          setFormData(prev => ({
            ...prev,
            email: data.user?.email || prev.email,
            // Update full_name in parent state too if it was provided during signup
            full_name: authData.full_name || prev.full_name
          }));
        }

        // Only move to beta details if we're still on the register step
        // This prevents unexpected state changes if the user has navigated away
        if (currentStep === 'register') {
          setAuthStep('beta-details');
        }
      } else {
        // Login flow
        const { data, error } = await supabase.auth.signInWithPassword({
          email: authData.email,
          password: authData.password!
        });

        if (error) throw error;

        // Save user info to the form state *after* successful auth
        if (data?.user?.email) {
          setFormData(prev => ({
            ...prev,
            email: data.user?.email || prev.email,
            // Pre-fill full_name from user metadata if available on login
            full_name: data.user?.user_metadata?.full_name || prev.full_name
          }));
        }

        toast({
          title: "Logged in successfully",
          description: "Please continue with your beta application.",
        });

        // Only move to beta details if we're still on the login step
        if (currentStep === 'login') {
          setAuthStep('beta-details');
        }
      }
    } catch (error: unknown) {
      toast({
        title: "Authentication Error",
        description: error instanceof AuthError ? error.message : 'An unexpected error occurred',
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle OAuth login with LinkedIn
  const handleOAuthLogin = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'linkedin_oidc',
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
        },
      });
      
      if (error) throw error;
      
      // The page will redirect, so we don't need to manually update state here
    } catch (error) {
      toast({
        title: "Authentication Error",
        description: error instanceof AuthError ? error.message : "Failed to authenticate with LinkedIn",
        variant: "destructive",
      });
    }
  };

  // Handle final form submission
  const handleSubmit = async (submitData: Omit<BetaApplicationForm, 'password'>) => {
    setIsSubmitting(true);
    try {
      // Store the email for the thank you message using passed data
      setSubmittedEmail(submitData.email);

      // Fetch fresh session but don't require it for submission
      const { data: { session } } = await supabase.auth.getSession();

      // Create base application data using passed data
      const applicationData = {
        ...submitData, // Use the data passed from the modal
        user_id: session?.user?.id || null,
        browser_info: navigator.userAgent,
        created_at: new Date().toISOString()
      };

      // Password field is already omitted from submitData type

      const { error } = await supabase.from('beta_applications').insert([applicationData]);
      if (error) {
        console.error('Supabase insert error:', error);
        throw error;
      }

      // We don't need to send a confirmation email anymore
      // await sendBetaApplicationEmail(submitData.email, submitData.full_name);

      // Check if the user needs to verify their email
      if (session?.user && !session.user.email_confirmed_at) {
        setNeedsEmailVerification(true);
      }

      // Change to thank you step instead of just closing the modal
      setAuthStep('thank-you');

      // Update user session state
      setUserSession(session);

      // Reset form fields (but keep the modal open for the thank you message)
      // Keep email if user is logged in
      setFormData({
        full_name: '',
        specialty: '',
        linkedin_url: '',
        linkedin_connections: 0,
        why_join_beta: '',
        email: session?.user?.email || '',
        password: ''
      });
    } catch (error) {
      // Log the specific error if possible
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
      console.error('Error submitting application:', errorMessage, error);
      toast({
        title: "Submission Error",
        description: "Something went wrong submitting your application. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle dialog close events consistently
  const handleModalOpenChange = (open: boolean) => {
    setShowApplicationModal(open);
    
    if (!open) {
      // Reset to first step for the next time modal opens
      // But delay this to avoid UI flashes when closing
      setTimeout(() => {
        setAuthStep('register');
        setNeedsEmailVerification(false);
      }, 300);
    }
  };

  // Modal component with different steps
  const ApplicationModal = () => {
    // Get access to toast
    const { toast } = useToast();
    
    // Use local state for the form values
    const [localFormData, setLocalFormData] = useState({
      email: formData.email || '',
      password: formData.password || '',
      full_name: formData.full_name || '', 
      specialty: formData.specialty || '',
      linkedin_url: formData.linkedin_url || '',
      linkedin_connections: formData.linkedin_connections || 0,
      why_join_beta: formData.why_join_beta || ''
    });
    
    // Keep the active tab in local state, separate from the workflow state
    const [activeTab, setActiveTab] = useState<string>(authStep);
    
    // Update the local state when authStep changes from parent
    useEffect(() => {
      // Only update the active tab if it's different from the current authStep
      // This prevents unnecessary re-renders during typing
      if (activeTab !== authStep) {
        setActiveTab(authStep);
      }
    }, [authStep]);
    
    // Sync local state with parent state when modal opens or authStep changes
    useEffect(() => {
      // When authStep changes or modal opens, update local form data
      // But only use parent values that actually exist (not empty strings)
      setLocalFormData(prevLocalData => ({
        ...prevLocalData,
        email: formData.email || prevLocalData.email,
        password: formData.password || prevLocalData.password,
        full_name: formData.full_name || prevLocalData.full_name,
        specialty: formData.specialty || prevLocalData.specialty,
        linkedin_url: formData.linkedin_url || prevLocalData.linkedin_url,
        linkedin_connections: formData.linkedin_connections || prevLocalData.linkedin_connections,
        why_join_beta: formData.why_join_beta || prevLocalData.why_join_beta
      }));
    }, [authStep, showApplicationModal, formData]);
    
    // Handle local input changes without affecting parent state
    const handleLocalInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value, id } = e.target;
      // Use 'name' for auth forms, 'id' for beta details form
      const fieldName = name || id;

      if (fieldName === 'linkedin_connections') {
        // Convert string to number for connections
        setLocalFormData(prev => ({
          ...prev,
          [fieldName]: parseInt(value) || 0
        }));
      } else {
        setLocalFormData(prev => ({
          ...prev,
          [fieldName]: value
        }));
      }
    };
    
    // Handle tab changes in a way that updates the parent's authStep
    // but doesn't cause re-renders that lose input focus
    const handleTabChange = (value: string) => {
      setActiveTab(value);
      // Update parent's authStep but don't re-render form contents
      if (value === 'login' || value === 'register') {
        setAuthStep(value as AuthStep);
      }
    };
    
    // Only update parent form state when necessary, not during typing
    const handleLocalAuth = async (type: 'register' | 'login') => {
      // Validate using local state before calling parent
      if (!localFormData.email?.trim()) {
        toast({ title: "Validation Error", description: "Email is required", variant: "destructive" });
        return;
      }

      if (type === 'register' && (!localFormData.password || localFormData.password.length < 6)) {
        toast({ title: "Validation Error", description: "Password must be at least 6 characters", variant: "destructive" });
        return;
      }

      if (type === 'login' && !localFormData.password) {
          toast({ title: "Validation Error", description: "Password is required for login.", variant: "destructive"});
          return;
      }

      // Prepare payload from local state
      const authPayload = {
          email: localFormData.email.trim(),
          password: localFormData.password,
          // Include full_name for registration if provided
          full_name: type === 'register' ? localFormData.full_name?.trim() : undefined
      };

      // Call the parent auth handler with validated local data
      await handleAuth(type, authPayload);
    };

    // Handle local form submission
    const handleLocalSubmit = async () => {
      // Validate beta details using local state before calling parent
      if (!localFormData.full_name?.trim()) {
        toast({ title: "Validation Error", description: "Full name is required", variant: "destructive" });
        return;
      }
      if (!localFormData.specialty?.trim()) {
        toast({ title: "Validation Error", description: "Primary specialty is required", variant: "destructive" });
        return;
      }
      if (!localFormData.linkedin_url?.trim()) {
        toast({ title: "Validation Error", description: "LinkedIn URL is required", variant: "destructive" });
        return;
      }
      // Check if connections > 0
      if (!localFormData.linkedin_connections || localFormData.linkedin_connections <= 0) {
        toast({ title: "Validation Error", description: "Number of LinkedIn connections is required", variant: "destructive" });
        return;
      }
      if (!localFormData.why_join_beta?.trim()) {
        toast({ title: "Validation Error", description: "Please tell us why you want to join the beta", variant: "destructive" });
        return;
      }

      // Prepare final payload from local state
      const submitPayload: Omit<BetaApplicationForm, 'password'> = {
        full_name: localFormData.full_name.trim(),
        specialty: localFormData.specialty.trim(),
        linkedin_url: localFormData.linkedin_url.trim(),
        linkedin_connections: localFormData.linkedin_connections,
        why_join_beta: localFormData.why_join_beta.trim(),
        // Use session email if available, otherwise use the email from local state (which might have been prefilled or entered)
        email: userSession?.user?.email || localFormData.email?.trim() || ''
      };

      // Call the parent submit handler with validated local data
      await handleSubmit(submitPayload);
    };
    
    // Function to close modal and navigate to a tool
    const navigateToTool = (path: string) => {
      setShowApplicationModal(false);
      navigate(path);
    };
    
    // Function to close modal for non-authenticated users
    const handleCloseThankYou = () => {
      setShowApplicationModal(false);
    };
    
    return (
      <Dialog open={showApplicationModal} onOpenChange={handleModalOpenChange}>
        <DialogContent className="max-w-[90%] sm:max-w-lg max-h-[90vh] overflow-y-auto p-6">
          <DialogHeader className="mb-4 text-left">
            <DialogTitle className="text-2xl font-semibold">
              {activeTab === 'thank-you' 
                ? "Application Submitted!"
                : "Beta Program Application"}
            </DialogTitle>
            <DialogDescription className="text-gray-600">
              {activeTab === 'beta-details' 
                ? "Complete this short form to apply. We're excited to potentially work with you!"
                : activeTab === 'thank-you'
                ? "Thank you for your interest in our beta program."
                : "Create an account or sign in to apply for the beta program."}
            </DialogDescription>
          </DialogHeader>
          
          {activeTab === 'register' || activeTab === 'login' ? (
            <div className="space-y-4">
              <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
                <TabsList className="grid w-full grid-cols-2 mb-4">
                  <TabsTrigger value="register">Sign Up</TabsTrigger>
                  <TabsTrigger value="login">Sign In</TabsTrigger>
                </TabsList>
                
                <TabsContent value="register" className="space-y-6">
                  <Button
                    variant="outline"
                    className="w-full flex items-center justify-center"
                    onClick={handleOAuthLogin}
                  >
                    <Linkedin className="mr-2 h-4 w-4" />
                    Continue with LinkedIn
                  </Button>

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-gray-500">Or continue with</span>
                    </div>
                  </div>
                
                  <div className="space-y-2">
                    <Label htmlFor="register-email" className="text-sm font-medium">Email</Label>
                    <Input 
                      id="register-email" 
                      name="email"
                      type="email" 
                      value={localFormData.email} 
                      onChange={handleLocalInputChange} 
                      placeholder="you@example.com" 
                      required
                      autoComplete="email"
                      autoFocus
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="register-password" className="text-sm font-medium">Password</Label>
                    <Input 
                      id="register-password" 
                      name="password"
                      type="password" 
                      value={localFormData.password} 
                      onChange={handleLocalInputChange} 
                      placeholder="••••••••" 
                      required 
                      autoComplete="new-password"
                    />
                  </div>
                  <Button 
                    onClick={() => handleLocalAuth('register')} 
                    disabled={isSubmitting} 
                    className="w-full"
                  >
                    {isSubmitting ? 'Creating Account...' : 'Create Account'}
                  </Button>
                </TabsContent>
                
                <TabsContent value="login" className="space-y-6">
                  <Button
                    variant="outline"
                    className="w-full flex items-center justify-center"
                    onClick={handleOAuthLogin}
                  >
                    <Linkedin className="mr-2 h-4 w-4" />
                    Continue with LinkedIn
                  </Button>

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-gray-500">Or continue with</span>
                    </div>
                  </div>
                
                  <div className="space-y-2">
                    <Label htmlFor="login-email" className="text-sm font-medium">Email</Label>
                    <Input 
                      id="login-email" 
                      name="email"
                      type="email" 
                      value={localFormData.email} 
                      onChange={handleLocalInputChange} 
                      placeholder="you@example.com" 
                      required 
                      autoComplete="email"
                      autoFocus
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="login-password" className="text-sm font-medium">Password</Label>
                    <Input 
                      id="login-password" 
                      name="password"
                      type="password" 
                      value={localFormData.password} 
                      onChange={handleLocalInputChange} 
                      placeholder="••••••••" 
                      required 
                      autoComplete="current-password"
                    />
                  </div>
                  <Button 
                    onClick={() => handleLocalAuth('login')} 
                    disabled={isSubmitting} 
                    className="w-full"
                  >
                    {isSubmitting ? 'Signing In...' : 'Sign In'}
                  </Button>
                </TabsContent>
              </Tabs>
            </div>
          ) : activeTab === 'thank-you' ? (
            <div className="space-y-6 py-4">
              <div className="flex justify-center mb-6">
                <div className="rounded-full bg-green-100 p-3">
                  <CheckCircle2 className="h-12 w-12 text-green-600" />
                </div>
              </div>
              
              <div className="text-center space-y-4">
                <h3 className="text-lg font-medium text-gray-900">
                  Thank you for applying to the Medical Post beta program!
                </h3>
                
                <p className="text-gray-600">
                  We've received your application and will review it shortly. 
                  {userSession ? " You can start exploring our tools right away!" : " We'll be in touch soon with next steps."}
                </p>
                
                {needsEmailVerification && (
                  <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 mt-4">
                    <h4 className="font-medium text-amber-800 mb-2">Please verify your email</h4>
                    <p className="text-amber-700 text-sm">
                      We've sent a verification link to <strong>{submittedEmail}</strong>. 
                      Please check your inbox and verify your email address to complete your registration.
                    </p>
                    <Button 
                      onClick={async () => {
                        try {
                          const { error } = await supabase.auth.resend({
                            type: 'signup',
                            email: submittedEmail,
                            options: {
                              emailRedirectTo: `${window.location.origin}/`
                            }
                          });
                          if (error) throw error;
                          toast({
                            title: "Verification email sent",
                            description: "Please check your inbox for the verification link."
                          });
                        } catch (error) {
                          toast({
                            title: "Error sending verification email",
                            description: error instanceof Error ? error.message : "Please try again later.",
                            variant: "destructive"
                          });
                        }
                      }}
                      variant="outline" 
                      size="sm"
                      className="mt-2 border-amber-300 bg-amber-100 hover:bg-amber-200 text-amber-800"
                    >
                      Resend verification email
                    </Button>
                  </div>
                )}
                
                {userSession ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-6">
                    <div className="space-y-3">
                      <h4 className="font-medium text-gray-800">Get Started with Our Tools</h4>
                      <Button 
                        onClick={() => navigateToTool('/optimizer')} 
                        className="w-full bg-primary hover:bg-primary/90"
                      >
                        Profile Optimizer
                      </Button>
                      <Button 
                        onClick={() => navigateToTool('/content-planner')} 
                        className="w-full bg-primary hover:bg-primary/90"
                      >
                        Content Planner
                      </Button>
                    </div>
                    <div className="space-y-3">
                      <h4 className="font-medium text-gray-800">Create Content</h4>
                      <Button 
                        onClick={() => navigateToTool('/post-creator')} 
                        className="w-full bg-primary hover:bg-primary/90"
                      >
                        Post Creator
                      </Button>
                      <Button 
                        onClick={() => navigateToTool('/carousel-creator')} 
                        className="w-full bg-primary hover:bg-primary/90"
                      >
                        Carousel Creator
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="pt-4">
                    <Button onClick={handleCloseThankYou} className="w-full">
                      Close
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <div>
                <Label htmlFor="full_name" className="text-sm font-medium">Full Name</Label>
                <Input 
                  id="full_name" 
                  value={localFormData.full_name} 
                  onChange={handleLocalInputChange} 
                  placeholder="e.g., Dr. Jane Smith" 
                  className="mt-1" 
                  required 
                />
              </div>
              <div>
                <Label htmlFor="specialty" className="text-sm font-medium">Primary Specialty / Role</Label>
                <Input 
                  id="specialty" 
                  value={localFormData.specialty} 
                  onChange={handleLocalInputChange} 
                  placeholder="e.g., Cardiology, General Practice, Nursing Lead" 
                  className="mt-1" 
                  required 
                />
              </div>
              <div>
                <Label htmlFor="linkedin_url" className="text-sm font-medium">Your LinkedIn Profile URL</Label>
                <Input 
                  id="linkedin_url" 
                  value={localFormData.linkedin_url} 
                  onChange={handleLocalInputChange} 
                  placeholder="https://linkedin.com/in/yourprofile" 
                  className="mt-1" 
                  required 
                />
              </div>
              <div>
                <Label htmlFor="linkedin_connections" className="text-sm font-medium">Approximate Number of LinkedIn Connections</Label>
                <Input 
                  id="linkedin_connections" 
                  type="number" 
                  value={localFormData.linkedin_connections || ''} 
                  onChange={handleLocalInputChange} 
                  placeholder="e.g., 500" 
                  className="mt-1" 
                  required 
                />
              </div>
              <div>
                <Label htmlFor="why_join_beta" className="text-sm font-medium">Why do you want to join our beta program?</Label>
                <Textarea 
                  id="why_join_beta" 
                  value={localFormData.why_join_beta} 
                  onChange={handleLocalInputChange} 
                  placeholder="Tell us why you're interested and how you might benefit..." 
                  className="mt-1 h-24" 
                  required 
                />
              </div>
              <DialogFooter className="mt-6 sm:justify-end">
                <Button variant="outline" onClick={() => setShowApplicationModal(false)} disabled={isSubmitting}>Cancel</Button>
                <Button onClick={handleLocalSubmit} disabled={isSubmitting} className="min-w-[130px]">
                  {isSubmitting ? (
                    <motion.span
                      animate={{ rotate: 360 }}
                      transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                      style={{ display: 'inline-block' }} className="mr-2"
                    >
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" > <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /> </svg>
                    </motion.span>
                  ) : null}
                  {isSubmitting ? 'Submitting...' : 'Submit Application'}
                </Button>
              </DialogFooter>
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  // Main component render
  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50/20 to-white relative" role="main">
      {/* SEO and meta tags */}
      <Helmet>
        <title>Medical Post Beta Program - Transform Your LinkedIn Presence</title>
        <meta name="description" content="Join the exclusive beta program for healthcare professionals. Get 3 weeks free access, exclusive features, and 40% lifetime discount." />
      </Helmet>
    
      {/* Background elements with parallax */}
      <div
        className="absolute inset-0 pointer-events-none z-0"
        style={{ transform: `translateY(${scrollY * 0.1}px)` }}
      >
        <div className="absolute inset-0 bg-[radial-gradient(theme(colors.gray.300)_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)] opacity-30"></div>
        <div className="absolute -top-40 -left-80 w-[700px] h-[700px] bg-gradient-to-r from-primary/5 to-primary/10 rounded-full blur-3xl opacity-60" />
        <div className="absolute -top-10 -right-80 w-[700px] h-[700px] bg-gradient-to-r from-sage-200/30 to-sage-100/20 rounded-full blur-3xl opacity-60" />
      </div>

      {/* Content Wrapper */}
      <div className="relative z-10">
        {/* Hero Section */}
        <main className="relative">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-20">
            {/* Hero content */}
            <motion.div 
              className="text-center mb-12 md:mb-16" 
              initial="initial"
              animate="animate"
              variants={staggerChildren}
            >
              <motion.h1 
                className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 tracking-tight"
                variants={fadeInUp}
              >
                <span className="inline-block">Transform Your</span>{" "}
                <span className="inline-block text-primary">LinkedIn Presence</span>
              </motion.h1>
              
              <motion.p 
                className="mt-6 text-lg md:text-xl text-gray-600 max-w-3xl mx-auto"
                variants={fadeInUp}
              >
                Join our exclusive beta program for healthcare professionals. Get curated content, enhanced engagement, and build a powerful personal brand on LinkedIn.
              </motion.p>
              
              <motion.div variants={fadeInUp} className="mt-8">
                <Button 
                  size="lg" 
                  onClick={() => setShowApplicationModal(true)}
                  className="bg-primary hover:bg-primary/90 text-white px-8 py-3 text-lg rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105"
                >
                  Apply for Beta Access
                </Button>
                <p className="mt-3 text-sm text-gray-500">
                  <BetaSpots totalSpots={25} /> spots remaining
                </p>
              </motion.div>
            </motion.div>
            
            {/* Rest of the page content... */}
            
            {/* Final CTA Section */}
            <motion.section
              className="text-center relative group my-16 py-16 px-6"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 0.6 }}
              aria-labelledby="final-cta-heading"
            >
              {/* Background effect */}
              <div className="absolute -inset-2 bg-gradient-to-r from-primary/80 to-blue-500/80 rounded-xl blur-lg opacity-20 group-hover:opacity-30 transition duration-1000 z-0" aria-hidden="true"></div>
              {/* Content Box */}
              <div className="relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-10 md:p-16 shadow-xl z-10">
                <h2 id="final-cta-heading" className="text-3xl md:text-4xl font-bold mb-4 text-white">Don't Miss Out on This Opportunity</h2>
                <p className="text-lg text-gray-300 mb-8 max-w-2xl mx-auto">
                  Applications close soon! Secure your spot among the first <BetaSpots totalSpots={25} /> healthcare professionals to transform their LinkedIn strategy.
                </p>
                <Button
                  size="lg"
                  onClick={() => setShowApplicationModal(true)}
                  className="bg-primary hover:bg-primary/90 text-white transform hover:scale-105 transition-transform duration-200 shadow-lg hover:shadow-primary/40"
                  aria-label="Apply for the beta program now"
                >
                  Apply Now - Limited Spots!
                </Button>
              </div>
            </motion.section>
          </div>
        </main>

        {/* Application Modal */}
        <ApplicationModal />
      </div>
    </div>
  );
};

export default BetaLandingPage;