import OpenAI from "openai";
import { supabase } from '@/integrations/supabase/client';
import type { Database } from '@/types/supabase';
import { IdealClientProfile } from '@/types/profile';
import { AI_MODELS } from '@/components/ui/model-selector';
import type { ClientOptions } from "openai";

import {
  ProfessionalAnalysis,
  analyzeProfilePrompt,
  validateProfessionalAnalysis
} from './prompts/analyzeProfile';

import {
  AudienceAnalysis,
  analyzeAudiencePrompt,
  validateAudienceAnalysis
} from './prompts/analyzeAudience';

import {
  ImprovementAnalysis,
  suggestImprovementsPrompt,
  validateImprovementAnalysis,
  formatImprovementAnalysis
} from './prompts/suggestImprovements';

import {
  AboutSection,
  rewriteAboutPrompt,
  validateAboutSection,
  formatAboutSection
} from './prompts/rewriteAbout';

export type AIModel = typeof AI_MODELS[number]['id'];

let currentModel: AIModel = 'deepseek-chat';

// Initialize DeepSeek client
const deepseekClient = new OpenAI({
  baseURL: 'https://api.deepseek.com',
  apiKey: import.meta.env.VITE_DEEPSEEK_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function initializeAIModel() {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.user) {
      const { data: profile } = await supabase
        .from('profiles')
        .select('default_model')
        .eq('id', session.user.id)
        .single();
      
      if (profile?.default_model) {
        currentModel = profile.default_model as AIModel;
        console.log('Initialized AI model from user settings:', currentModel);
      }
    }
  } catch (error) {
    console.error('Error initializing AI model from settings:', error);
  }
}

export function getCurrentModel(): AIModel {
  return currentModel;
}

export function setAIModel(model: AIModel) {
  console.log('Switching to model:', model);
  currentModel = model;
}

function getModelConfig(model: AIModel) {
  const modelConfig = AI_MODELS.find(m => m.id === model);
  if (!modelConfig) throw new Error(`Invalid model: ${model}`);
  return modelConfig;
}

function createOpenAIClient(baseUrl?: string, apiKey?: string) {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  };

  const clientOptions: ClientOptions = {
    baseURL: baseUrl,
    apiKey: apiKey || import.meta.env.VITE_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
    defaultHeaders: headers
  };

  return new OpenAI(clientOptions);
}

interface OpenRouterRequestOptions {
  model: string;
  messages: Array<{ role: string; content: string }>;
  temperature?: number;
  max_tokens?: number;
  presence_penalty?: number;
  frequency_penalty?: number;
  response_format?: { type: string };
  top_p?: number;
  top_k?: number;
  stream?: boolean;
  stop?: string | string[];
  repetition_penalty?: number;
}

async function makeOpenRouterRequest(requestOptions: OpenRouterRequestOptions) {
  const response = await fetch("https://openrouter.ai/api/v1/chat/completions", {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${import.meta.env.VITE_OPENROUTER_API_KEY}`,
      "HTTP-Referer": window.location.origin,
      "X-Title": "Medical Post",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestOptions)
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => null);
    throw new Error(`OpenRouter API error: ${response.status} - ${errorData?.error?.message || response.statusText}`);
  }

  const data = await response.json();
  
  // Validate response structure
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid response format from OpenRouter');
  }

  // Check for API-level errors
  if (data.error) {
    throw new Error(`OpenRouter API error: ${data.error.message || JSON.stringify(data.error)}`);
  }

  // Validate choices array
  if (!Array.isArray(data.choices) || data.choices.length === 0) {
    throw new Error('No choices returned from OpenRouter');
  }

  // Validate first choice has message and content
  const firstChoice = data.choices[0];
  if (!firstChoice?.message?.content) {
    throw new Error('Invalid choice format from OpenRouter');
  }

  return data;
}

// Add a delay helper function
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const cleanJsonResponse = (response: string): string => {
  console.log('Raw response before cleaning:', response);
  
  try {
    // First try: direct JSON parse
    try {
      JSON.parse(response);
      return response;
    } catch (e) {
      console.log('Direct JSON parse failed, attempting to clean response');
    }

    // Remove markdown code block markers and find JSON content
    let cleaned = response;
    
    // Try to extract content between ```json and ``` markers
    const jsonBlockMatch = cleaned.match(/```json\n([\s\S]*?)\n```/);
    if (jsonBlockMatch) {
      cleaned = jsonBlockMatch[1];
      console.log('Found JSON block between markers:', cleaned);
    } else {
      // If no json block found, try to find any code block
      const codeBlockMatch = cleaned.match(/```([\s\S]*?)```/);
      if (codeBlockMatch) {
        cleaned = codeBlockMatch[1];
        console.log('Found code block between markers:', cleaned);
      }
    }

    // Remove any remaining markdown code block markers
    cleaned = cleaned.replace(/```json\n?/g, '')
      .replace(/```\n?/g, '')
      .replace(/```\w*\n?/g, '');

    // Remove any leading/trailing whitespace and normalize newlines
    cleaned = cleaned.trim().replace(/\r\n/g, '\n');

    // Try to find a JSON object if the response contains multiple parts
    const jsonMatch = cleaned.match(/(\{[\s\S]*\})/);
    if (jsonMatch) {
      cleaned = jsonMatch[1];
      console.log('Extracted JSON object:', cleaned);
    }

    // Validate that we have valid JSON
    try {
      JSON.parse(cleaned);
      console.log('Successfully cleaned and parsed JSON');
      return cleaned;
    } catch (e) {
      console.error('Failed to parse cleaned JSON:', e);
      throw new Error('Failed to parse JSON after cleaning');
    }
  } catch (error) {
    console.error('Error in cleanJsonResponse:', error);
    throw error;
  }
};

export async function generateCompletion(prompt: string, model: AIModel = currentModel) {
  const modelConfig = getModelConfig(model);

  try {
    if (!modelConfig.provider) {
      throw new Error(`Provider not specified for model: ${model}`);
    }

    switch (modelConfig.provider) {
      case 'deepseek': {
        const completion = await deepseekClient.chat.completions.create({
          model: model === 'deepseek-reasoner' ? 'deepseek-reasoner' : 'deepseek-chat',
          messages: [{ role: 'user', content: prompt }]
        });
        return completion.choices[0]?.message?.content || '';
      }
      
      case 'openrouter': {
        const response = await makeOpenRouterRequest({
          model: modelConfig.config.model,
          messages: [{ role: 'user', content: prompt }],
          temperature: 0.7,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          repetition_penalty: 1
        });
        return response.choices[0]?.message?.content || '';
      }

      case 'openai': {
        const client = createOpenAIClient();
        const completion = await client.chat.completions.create({
          model: modelConfig.config.model,
          messages: [{ role: 'user', content: prompt }]
        });
        return completion.choices[0]?.message?.content || '';
      }
    }
  } catch (error) {
    console.error('Error generating completion:', error);
    throw error;
  }
}

// Helper function to get the actual model name to use with the API
function getApiModelName(model: AIModel): string {
  const modelConfig = getModelConfig(model);
  if ('config' in modelConfig && modelConfig.config?.model) {
    return modelConfig.config.model;
  }
  return model;
}

function truncateText(text: string, maxLength: number = 6000): string {
  if (text.length <= maxLength) return text;
  
  // Try to find a sentence boundary near the middle
  const midPoint = Math.floor(maxLength / 2);
  let firstHalf = text.slice(0, midPoint).trim();
  let secondHalf = text.slice(-midPoint).trim();
  
  // Try to break at sentence boundaries
  const firstSentenceEnd = firstHalf.lastIndexOf('.');
  const secondSentenceStart = secondHalf.indexOf('.');
  
  if (firstSentenceEnd !== -1) {
    firstHalf = firstHalf.slice(0, firstSentenceEnd + 1);
  }
  if (secondSentenceStart !== -1) {
    secondHalf = secondHalf.slice(secondSentenceStart + 1);
  }
  
  return `${firstHalf.trim()}\n...\n${secondHalf.trim()}`;
}

export const makeAIRequest = async (
  prompt: string, 
  systemPrompt?: string,
  model: AIModel = currentModel
): Promise<string> => {
  const maxRetries = 3;
  let attempt = 1;
  let lastError: Error | null = null;

  while (attempt <= maxRetries) {
    try {
      const modelConfig = getModelConfig(model);
      console.log(`[Attempt ${attempt}] Making request with model:`, model, 'Config:', modelConfig);

      const messages = [
        ...(systemPrompt ? [{ role: 'system', content: systemPrompt }] : []),
        { role: 'user', content: prompt }
      ];

      const response = await fetch('https://openrouter.ai/api/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${import.meta.env.VITE_OPENROUTER_API_KEY}`,
          'HTTP-Referer': 'https://profile-revision-wizard.vercel.app/',
          'X-Title': 'Profile Revision Wizard'
        },
        body: JSON.stringify({
          model: getApiModelName(model),
          messages
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error(`[Attempt ${attempt}] API Error:`, {
          status: response.status,
          statusText: response.statusText,
          errorData
        });
        throw new Error(`HTTP error! status: ${response.status} - ${errorData?.error?.message || response.statusText}`);
      }

      const data = await response.json();
      const content = data.choices[0]?.message?.content;

      if (!content) {
        console.error(`[Attempt ${attempt}] No content in response:`, data);
        throw new Error('No content in response');
      }

      // Clean the response if it contains JSON
      if (prompt.includes('Return the content as a valid JSON')) {
        return cleanJsonResponse(content);
      }

      return content;
    } catch (error) {
      console.error(`[Attempt ${attempt}] Request failed:`, error);
      lastError = error as Error;
      attempt++;
      
      // Add a small delay before retrying
      await delay(1000 * attempt);
    }
  }

  throw lastError || new Error('Request failed after all retries');
};

export async function analyzeProfile(profileText: string): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log(`[${requestId}] Starting profile analysis`);
  console.time(`analyze_profile_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log(`[${requestId}] Truncated text length:`, truncatedText.length);
    
    let response = await makeAIRequest(
      analyzeProfilePrompt.replace('{{profile}}', truncatedText),
      currentModel
    );

    // Clean the response if it's wrapped in markdown
    if (response.includes('```')) {
      response = cleanJsonResponse(response);
    }

    // Log the raw response for debugging
    console.log(`[${requestId}] Raw response:`, response);

    const jsonResponse = JSON.parse(response);
    console.log(`[${requestId}] Successfully parsed profile analysis response:`, jsonResponse);
    
    if (!validateProfessionalAnalysis(jsonResponse)) {
      console.error(`[${requestId}] Invalid response structure:`, jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    console.timeEnd(`analyze_profile_${requestId}`);
    return jsonResponse.description;
  } catch (error) {
    console.error(`[${requestId}] Profile analysis failed:`, error);
    throw error;
  }
}

export async function analyzeAudience(profileText: string): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log(`[${requestId}] Starting audience analysis`);
  console.time(`analyze_audience_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log(`[${requestId}] Truncated text length:`, truncatedText.length);
    
    let response = await makeAIRequest(
      analyzeAudiencePrompt.replace('{{profile}}', truncatedText),
      currentModel
    );

    // Clean the response if it's wrapped in markdown
    if (response.includes('```')) {
      response = cleanJsonResponse(response);
    }

    // Log the raw response for debugging
    console.log(`[${requestId}] Raw response:`, response);

    const jsonResponse = JSON.parse(response);
    console.log(`[${requestId}] Successfully parsed audience analysis response:`, jsonResponse);
    
    if (!validateAudienceAnalysis(jsonResponse)) {
      console.error(`[${requestId}] Invalid response structure:`, jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    console.timeEnd(`analyze_audience_${requestId}`);
    return jsonResponse.description;
  } catch (error) {
    console.error(`[${requestId}] Audience analysis failed:`, error);
    throw error;
  }
}

export async function suggestImprovements(profileText: string): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log(`[${requestId}] Starting improvements analysis`);
  console.time(`suggest_improvements_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log(`[${requestId}] Truncated text length:`, truncatedText.length);
    
    let response = await makeAIRequest(
      suggestImprovementsPrompt.replace('{{profile}}', truncatedText),
      currentModel
    );

    // Clean the response if it's wrapped in markdown
    if (response.includes('```')) {
      response = cleanJsonResponse(response);
    }

    // Log the raw response for debugging
    console.log(`[${requestId}] Raw response:`, response);

    const jsonResponse = JSON.parse(response);
    console.log(`[${requestId}] Successfully parsed improvements analysis response:`, jsonResponse);
    
    if (!validateImprovementAnalysis(jsonResponse)) {
      console.error(`[${requestId}] Invalid response structure:`, jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    console.timeEnd(`suggest_improvements_${requestId}`);
    return formatImprovementAnalysis(jsonResponse);
  } catch (error) {
    console.error(`[${requestId}] Improvements analysis failed:`, error);
    throw error;
  }
}

export async function rewriteAboutSection(
  profileText: string,
  tone: string,
  additionalDetails: string
): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log('Starting about section rewrite');
  console.time(`rewrite_about_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log('Truncated text length:', truncatedText.length);
    
    const prompt = `Use a ${tone} tone. Additional context: ${additionalDetails}. ${rewriteAboutPrompt}`;
    let response = await makeAIRequest(prompt, currentModel);

    // Clean the response if it's wrapped in markdown
    if (response.includes('```')) {
      response = cleanJsonResponse(response);
    }

    const jsonResponse = JSON.parse(response);
    if (!validateAboutSection(jsonResponse)) {
      console.error('Invalid response structure:', jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    const formatted = formatAboutSection(jsonResponse);
    console.timeEnd(`rewrite_about_${requestId}`);
    return formatted;
  } catch (error) {
    console.error('About section rewrite failed:', error);
    throw error;
  }
}

export async function saveProfileReview(userId: string, analysis: string) {
  console.log('Saving profile review');
  console.time('save_review');
  
  try {
    const { error } = await supabase
      .from('profile_reviews')
      .insert({
        user_id: userId,
        analysis: analysis,
        created_at: new Date().toISOString()
      });

    if (error) throw error;
    console.timeEnd('save_review');
  } catch (error) {
    console.error('Error saving profile review:', error);
    throw new Error('Failed to save profile review');
  }
}

export const defaultAnalysisSteps = [
  {
    name: 'Analyzing current profile',
    status: 'pending',
    weight: 0.4,
    result: null
  },
  {
    name: 'Identifying target audience',
    status: 'pending',
    weight: 0.3,
    result: null
  },
  {
    name: 'Suggesting improvements',
    status: 'pending',
    weight: 0.3,
    result: null
  }
];