import { makeAIRequest, getCurrentModel } from "@/services/deepseek";
import type { AIModel } from "@/services/deepseek";
import { MultiSlideSchema } from "./validation/slide-schema";

export async function generateCarouselSlidesAction(
  userPrompt: string, 
  apiKey: string,
  numPages: number = 3,
  model: AIModel = 'deepseek-chat'
) {
  if (!apiKey) {
    throw new Error("API key is required");
  }

  try {
    // Create the structured prompt for carousel generation
    const prompt = `Create a ${numPages}-page carousel about "${userPrompt}".
Each page should be concise and impactful. Keep titles short and punchy (max 30 characters).`;

    const systemPrompt = `You are a helpful assistant that creates engaging carousel content. 
You must return a valid JSON object with a 'slides' array containing exactly ${numPages} carousel pages.

The response MUST follow this exact structure:
{
  "slides": [
    {
      "elements": [
        {
          "type": "Title",
          "content": "Short, Punchy Title",
          "style": {
            "fontSize": "Medium",
            "align": "Center",
            "margin": "Medium",
            "color": "Primary",
            "width": "Large"
          }
        },
        {
          "type": "Subtitle",
          "content": "Brief overview or hook",
          "style": {
            "fontSize": "Medium",
            "align": "Center",
            "margin": "Small",
            "color": "Secondary",
            "width": "Large"
          }
        }
      ]
    },
    {
      "elements": [
        {
          "type": "Subtitle",
          "content": "Key point",
          "style": {
            "fontSize": "Medium",
            "align": "Left",
            "margin": "Medium",
            "color": "Primary",
            "width": "Large"
          }
        },
        {
          "type": "Description",
          "content": "Supporting details",
          "style": {
            "fontSize": "Small",
            "align": "Left",
            "margin": "Small",
            "color": "Secondary",
            "width": "Large"
          }
        }
      ]
    }
  ]
}

Requirements:
1. First slide must have both Title and Subtitle elements
2. Other slides must have Subtitle and Description elements
3. Keep content concise and engaging
4. Title should be short and punchy (max 30 characters)
5. All elements MUST include ALL style properties (fontSize, align, margin, color, width)
6. Return ONLY the JSON object, no other text`;

    // Use the makeAIRequest function which handles model-specific configurations
    const response = await makeAIRequest(prompt, systemPrompt, getCurrentModel());
    console.log('Raw AI response:', response);

    try {
      // First, clean the response if needed
      const cleanResponse = response.trim().replace(/^```json\n|\n```$/g, '');
      
      // Parse the JSON response
      const parsedContent = JSON.parse(cleanResponse);
      
      // Validate the slides array exists
      if (!parsedContent.slides || !Array.isArray(parsedContent.slides)) {
        throw new Error('Invalid response format: missing slides array');
      }

      // Validate the slides structure
      const slides = MultiSlideSchema.parse(parsedContent.slides);
      
      // Ensure we have the correct number of slides
      if (slides.length !== numPages) {
        throw new Error(`Expected ${numPages} slides but got ${slides.length}`);
      }

      // Validate first slide has Title and Subtitle with all required style properties
      const firstSlide = slides[0];
      const titleElement = firstSlide.elements.find(el => el.type === "Title");
      const subtitleElement = firstSlide.elements.find(el => el.type === "Subtitle");
      
      if (!titleElement || !subtitleElement) {
        throw new Error('First slide must have both Title and Subtitle elements');
      }

      // Validate title length
      if (titleElement.content.length > 30) {
        titleElement.content = titleElement.content.substring(0, 27) + '...';
      }

      // Ensure all style properties are present
      const requiredStyles = ['fontSize', 'align', 'margin', 'color', 'width'];
      for (const slide of slides) {
        for (const element of slide.elements) {
          for (const style of requiredStyles) {
            if (!element.style[style]) {
              element.style[style] = getDefaultStyle(style);
            }
          }
        }
      }

      return slides;
    } catch (error) {
      console.error('Error parsing model response:', error);
      console.error('Raw response:', response);
      throw new Error('Failed to parse generated content. Please try again.');
    }

  } catch (error) {
    console.error("Error generating slides:", error);
    throw error;
  }
}

function getDefaultStyle(style: string): string {
  switch (style) {
    case 'fontSize':
      return 'Medium';
    case 'align':
      return 'Left';
    case 'margin':
      return 'Medium';
    case 'color':
      return 'Primary';
    case 'width':
      return 'Large';
    default:
      return 'Medium';
  }
} 