import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';

const BetaInvite = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const navigate = useNavigate();
  const { toast } = useToast();
  
  const [loading, setLoading] = useState(true);
  const [verifying, setVerifying] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inviteValid, setInviteValid] = useState(false);
  const [user, setUser] = useState<{ email: string; full_name: string } | null>(null);

  useEffect(() => {
    const verifyInvite = async () => {
      try {
        setVerifying(true);
        
        if (!token || !email) {
          throw new Error('Invalid invitation link. Missing required parameters.');
        }
        
        // Check if the invite token is valid
        const { data: invite, error: inviteError } = await supabase
          .from('beta_invites')
          .select('*')
          .eq('token', token)
          .eq('email', email)
          .eq('status', 'pending')
          .single();
          
        if (inviteError || !invite) {
          throw new Error('Invalid or expired invitation token.');
        }
        
        // Check if the token has expired
        if (new Date(invite.expires_at) < new Date()) {
          throw new Error('This invitation has expired. Please contact support for assistance.');
        }
        
        // Get the beta application for this email
        const { data: application, error: applicationError } = await supabase
          .from('beta_applications')
          .select('email, full_name, status')
          .eq('email', email)
          .eq('status', 'approved')
          .single();
          
        if (applicationError || !application) {
          throw new Error('Your beta application could not be found or is not approved.');
        }
        
        // Check if this email already has an account
        const { data: existingUser } = await supabase
          .from('profiles')
          .select('id')
          .eq('email', email)
          .single();
          
        if (existingUser) {
          // User already has an account, redirect to login
          toast({
            title: "Account already exists",
            description: "You already have an account. Please log in to access the beta program.",
          });
          navigate('/login');
          return;
        }
        
        setUser({
          email: application.email,
          full_name: application.full_name
        });
        setInviteValid(true);
      } catch (error) {
        console.error('Error verifying invite:', error);
        toast({
          title: "Invalid Invitation",
          description: error instanceof Error ? error.message : "Something went wrong with your invitation link.",
          variant: "destructive",
        });
        setTimeout(() => navigate('/'), 3000);
      } finally {
        setVerifying(false);
        setLoading(false);
      }
    };
    
    verifyInvite();
  }, [token, email, navigate, toast]);
  
  const handleSetupAccount = async () => {
    if (!user?.email || !token) return;
    
    if (!password) {
      toast({
        title: "Password required",
        description: "Please enter a password.",
        variant: "destructive",
      });
      return;
    }
    
    if (password.length < 6) {
      toast({
        title: "Password too short",
        description: "Password must be at least 6 characters long.",
        variant: "destructive",
      });
      return;
    }
    
    if (password !== confirmPassword) {
      toast({
        title: "Passwords don't match",
        description: "Please make sure your passwords match.",
        variant: "destructive",
      });
      return;
    }
    
    try {
      setLoading(true);
      
      // Create the account
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: user.email,
        password: password,
        options: {
          data: {
            full_name: user.full_name,
          },
        },
      });
      
      if (authError) throw authError;
      
      if (authData?.user) {
        // Mark the invite as used
        const { error: updateInviteError } = await supabase
          .from('beta_invites')
          .update({
            status: 'used',
            used_at: new Date().toISOString()
          })
          .eq('token', token)
          .eq('email', user.email);
          
        if (updateInviteError) {
          console.error('Error updating invite status:', updateInviteError);
        }
        
        // Enable beta features for this user
        const { error: updateError } = await supabase
          .from('profiles')
          .update({
            is_beta_user: true,
            beta_start_date: new Date().toISOString(),
          })
          .eq('id', authData.user.id);
          
        if (updateError) {
          console.error('Error updating beta status:', updateError);
        }
        
        toast({
          title: "Account Created!",
          description: "Your beta account has been set up successfully. Welcome to the Medical Post Beta Program!",
        });
        
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error setting up account:', error);
      toast({
        title: "Account Setup Failed",
        description: error instanceof Error ? error.message : "Something went wrong while setting up your account.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };
  
  if (verifying) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white flex items-center justify-center p-4">
        <div className="text-center">
          <div className="inline-block animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full mb-4"></div>
          <p className="text-gray-600">Verifying your invitation...</p>
        </div>
      </div>
    );
  }
  
  if (!inviteValid) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white flex items-center justify-center p-4">
        <div className="text-center max-w-md">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Invalid Invitation</h2>
          <p className="text-gray-600 mb-4">The invitation link you used is invalid or has expired.</p>
          <p className="text-gray-600">Redirecting you to the homepage...</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white flex items-center justify-center p-4">
      <Helmet>
        <title>Set Up Your Beta Account | Medical Post</title>
        <meta name="description" content="Complete your account setup to join the Medical Post Beta Program." />
      </Helmet>
      
      <motion.div 
        className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Welcome to the Beta Program!</h1>
          <p className="mt-2 text-gray-600">Complete your account setup to get started</p>
        </div>
        
        <div className="space-y-4 mb-6">
          <div>
            <p className="text-sm font-medium text-gray-700 mb-1">Email</p>
            <p className="p-2 bg-gray-50 rounded border border-gray-200">{user?.email}</p>
          </div>
          
          <div>
            <p className="text-sm font-medium text-gray-700 mb-1">Full Name</p>
            <p className="p-2 bg-gray-50 rounded border border-gray-200">{user?.full_name}</p>
          </div>
          
          <div>
            <label htmlFor="password" className="text-sm font-medium text-gray-700">Create Password</label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              className="mt-1"
              placeholder="Enter a secure password"
            />
          </div>
          
          <div>
            <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-700">Confirm Password</label>
            <Input
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={loading}
              className="mt-1"
              placeholder="Confirm your password"
            />
          </div>
        </div>
        
        <Button
          className="w-full"
          onClick={handleSetupAccount}
          disabled={loading}
        >
          {loading ? 'Setting Up Account...' : 'Complete Setup & Join Beta'}
        </Button>
        
        <p className="mt-4 text-center text-sm text-gray-500">
          By creating an account, you agree to our{' '}
          <a href="/terms" className="text-primary hover:underline">Terms of Service</a> and{' '}
          <a href="/privacy" className="text-primary hover:underline">Privacy Policy</a>.
        </p>
      </motion.div>
    </div>
  );
};

export default BetaInvite; 