export interface Improvement {
  area: string;
  issue: string;
  impact: string;
  fix: string;
}

export interface ImprovementAnalysis {
  improvements: Improvement[];
}

export const suggestImprovementsPrompt = `You are a professional profile analyzer. Your task is to analyze the LinkedIn About section and return ONLY a valid JSON object with the following structure. Do not include any text outside the JSON object. Do not include any explanations, apologies, or additional text. The response must be valid JSON that can be parsed directly.

{
  "improvements": [
    {
      "area": "Professional Story",
      "issue": "Example: Lacks clear career progression",
      "impact": "Example: Readers can't see growth and achievements",
      "fix": "Example: Add a brief timeline highlighting key promotions and major projects"
    },
    {
      "area": "Engagement",
      "issue": "Example: No mention of problem-solving approach",
      "impact": "Example: Misses opportunity to demonstrate value",
      "fix": "Example: Include specific examples of challenges overcome"
    },
    {
      "area": "Call to Action",
      "issue": "Example: Missing clear next steps",
      "impact": "Example: Readers don't know how to engage",
      "fix": "Example: Add invitation to connect for healthcare innovation discussions"
    }
  ]
}

Profile to analyze:
{{profile}}

Remember: Return ONLY the JSON object, no additional text.`;

export function validateImprovementAnalysis(json: unknown): json is ImprovementAnalysis {
  if (!json || typeof json !== 'object') return false;
  const analysis = json as ImprovementAnalysis;
  
  return !!(
    Array.isArray(analysis.improvements) &&
    analysis.improvements.length > 0 &&
    analysis.improvements.every(imp => 
      imp.area && imp.issue && imp.impact && imp.fix
    )
  );
}

export function formatImprovementAnalysis(analysis: ImprovementAnalysis): string {
  let formattedText = '';
  
  // Group improvements by area
  const groupedImprovements = analysis.improvements.reduce((acc, imp) => {
    if (!acc[imp.area]) {
      acc[imp.area] = [];
    }
    acc[imp.area].push(imp);
    return acc;
  }, {} as Record<string, Improvement[]>);

  // Format each area
  Object.entries(groupedImprovements).forEach(([area, improvements]) => {
    formattedText += `## ${area}\n\n`;
    improvements.forEach(imp => {
      formattedText += `- Issue: ${imp.issue}\n`;
      formattedText += `- Impact: ${imp.impact}\n`;
      formattedText += `- Fix: ${imp.fix}\n\n`;
    });
  });

  return formattedText;
} 