import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { sendBetaApprovalEmail, sendBetaWaitlistEmail } from '@/integrations/supabase/email';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Switch } from '@/components/ui/switch';
import { AI_MODELS } from '@/components/ui/model-selector';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Loader, RefreshCw, Mail } from 'lucide-react';

interface Statistics {
  totalUsers: number;
  subscribedUsers: number;
  totalPosts: number;
  totalContentPlans: number;
  totalHookSuggestions: number;
  totalProfileReviews: number;
  recentFeedback: FeedbackWithProfile[];
  recentUsers: RecentUser[];
  recentPosts: PostWithProfile[];
  recentPlans: PlanWithProfile[];
  subscriptionStats: {
    activeSubscriptions: number;
    canceledSubscriptions: number;
    conversionRate: number;
    averageSubscriptionAge: number;
    newSubscriptionsLast30Days: number;
  };
  postTypeDistribution: Record<string, number>;
  userStats: {
    totalPosts: number;
    averagePostsPerUser: number;
    newSignupsLast30Days: number;
    averagePostLength: number;
  };
}

interface RecentUser {
  email: string;
  created_at: string;
  is_subscribed: boolean;
}

interface FeedbackWithProfile {
  id: string;
  content: string;
  created_at: string;
  user_id: string;
  status: string;
  profiles: {
    email: string;
  };
}

interface PostWithProfile {
  id: string;
  title: string;
  type: string;
  content: string;
  created_at: string;
  status: string;
  user_id: string;
  profiles: {
    email: string;
  };
}

interface PlanWithProfile {
  id: string;
  name: string;
  created_at: string;
  posts_per_week: number;
  status: string;
  user_id: string;
  profiles: {
    email: string;
  };
}

type SupabaseResponse<T> = {
  data: T[] | null;
  error: Error | null;
};

interface DatabaseProfile {
  email: string;
}

interface DatabaseFeedback {
  id: string;
  content: string;
  created_at: string;
  user_id: string;
  status: string;
  profiles: DatabaseProfile;
}

interface DatabasePost {
  id: string;
  title: string;
  type: string;
  content: string;
  created_at: string;
  status: string;
  user_id: string;
  profiles: DatabaseProfile;
}

interface DatabasePlan {
  id: string;
  name: string;
  created_at: string;
  posts_per_week: number;
  status: string;
  user_id: string;
  profiles: DatabaseProfile;
}

interface UserTableDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  showSubscribedOnly: boolean;
}

function UserTableDialog({ open, onOpenChange, title, showSubscribedOnly }: UserTableDialogProps) {
  const [users, setUsers] = useState<Array<{
    id: string;
    email: string;
    created_at: string;
    is_subscribed: boolean;
    last_login: string;
    total_posts: number;
    total_content_plans: number;
    full_name: string;
    usage_count: number;
  }>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchUsers();
    }
  }, [open]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      let query = supabase
        .from('profiles')
        .select(`
          id,
          email,
          full_name,
          created_at,
          is_subscribed,
          usage_count,
          posts(count),
          content_plans(count)
        `)
        .order('created_at', { ascending: false });

      if (showSubscribedOnly) {
        query = query.eq('is_subscribed', true);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Query error:', error);
        throw error;
      }

      const formattedUsers = data.map(user => ({
        id: user.id,
        email: user.email,
        full_name: user.full_name || 'N/A',
        created_at: user.created_at,
        is_subscribed: user.is_subscribed,
        usage_count: user.usage_count || 0,
        total_posts: user.posts?.[0]?.count ?? 0,
        total_content_plans: user.content_plans?.[0]?.count ?? 0,
        last_login: 'Never' // Default since last_seen might not exist
      }));

      setUsers(formattedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
          </div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Email</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Joined</TableHead>
                <TableHead>Usage Count</TableHead>
                <TableHead>Subscription</TableHead>
                <TableHead>Posts</TableHead>
                <TableHead>Plans</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.full_name}</TableCell>
                  <TableCell>{new Date(user.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>{user.usage_count}</TableCell>
                  <TableCell>{user.is_subscribed ? 'Pro' : 'Free'}</TableCell>
                  <TableCell>{user.total_posts}</TableCell>
                  <TableCell>{user.total_content_plans}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
    </Dialog>
  );
}

interface LLMSetting {
  id: string;
  enabled: boolean;
  updated_at: string;
  updated_by: string;
}

function LLMSettingsTab() {
  const [settings, setSettings] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('llm_settings')
        .select('id, enabled');

      if (error) throw error;

      const settingsMap = (data || []).reduce((acc, setting) => {
        acc[setting.id] = setting.enabled;
        return acc;
      }, {} as Record<string, boolean>);

      // Initialize any missing models with default enabled state
      AI_MODELS.forEach(model => {
        if (settingsMap[model.id] === undefined) {
          settingsMap[model.id] = true;
        }
      });

      setSettings(settingsMap);
    } catch (error) {
      console.error('Error fetching LLM settings:', error);
      toast({
        title: "Error",
        description: "Failed to load LLM settings",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateModelStatus = async (modelId: string, enabled: boolean) => {
    try {
      const { error } = await supabase
        .from('llm_settings')
        .upsert({
          id: modelId,
          enabled,
          updated_at: new Date().toISOString(),
        });

      if (error) throw error;

      setSettings(prev => ({
        ...prev,
        [modelId]: enabled
      }));

      toast({
        title: "Success",
        description: `${enabled ? 'Enabled' : 'Disabled'} ${AI_MODELS.find(m => m.id === modelId)?.label}`,
      });
    } catch (error) {
      console.error('Error updating LLM setting:', error);
      toast({
        title: "Error",
        description: "Failed to update LLM setting",
        variant: "destructive",
      });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Model Availability</h3>
        <div className="space-y-4">
          {AI_MODELS.map((model) => (
            <div key={model.id} className="flex items-center justify-between border-b pb-4 last:border-b-0">
              <div>
                <p className="font-medium">{model.label}</p>
                <p className="text-sm text-gray-500">{model.description}</p>
                <p className="text-xs text-gray-400">Provider: {model.provider}</p>
              </div>
              <Switch
                checked={settings[model.id] || false}
                onCheckedChange={(checked) => updateModelStatus(model.id, checked)}
              />
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}

interface BetaApplication {
  id: string;
  full_name: string;
  specialty: string;
  linkedin_url: string;
  linkedin_connections: number;
  why_join_beta: string;
  email: string;
  user_id: string | null;
  created_at: string;
  updated_at: string;
  status: 'pending' | 'approved' | 'rejected' | 'waitlist';
  is_healthcare_professional: boolean;
  is_content_creator: boolean;
  notes: string | null;
  application_source: string;
  browser_info: string | null;
  first_name: string;
  last_name: string;
}

function BetaApplicationsTab() {
  const [applications, setApplications] = useState<BetaApplication[]>([]);
  const [loading, setLoading] = useState(true);
  const [approvedCount, setApprovedCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isTestEmailLoading, setIsTestEmailLoading] = useState(false);
  const TOTAL_BETA_SPOTS = 25;
  const { toast } = useToast();

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('beta_applications')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching applications:', error);
        throw error;
      }

      setApplications(data || []);
      const approved = (data || []).filter(app => app.status === 'approved').length;
      setApprovedCount(approved);
    } catch (error) {
      console.error('Error fetching applications:', error);
      toast({
        title: "Error",
        description: "Failed to load beta applications",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBetaApplication = async (application: BetaApplication, newStatus: string) => {
    setIsProcessing(true);
    
    try {
      // First check if we need to generate an invite
      let inviteUrl = null;
      if (newStatus === 'approved') {
        // Check if user already exists in our system
        const { data: existingUser, error: queryError } = await supabase
          .from('profiles')
          .select('id')
          .eq('email', application.email)
          .single();
        
        if (queryError && queryError.code !== 'PGRST116') { // PGRST116 is "No rows returned"
          console.error('Error checking if user exists:', queryError);
          toast({
            title: "Error",
            description: "Error checking if user exists",
            variant: "destructive"
          });
          setIsProcessing(false);
          return;
        }
        
        // Only generate invite if user doesn't exist yet
        if (!existingUser) {
          // Generate a secure token for the invite
          const token = typeof crypto !== 'undefined' && crypto.randomUUID 
            ? crypto.randomUUID() 
            : Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          
          // Set expiry date to 7 days from now
          const expiresAt = new Date();
          expiresAt.setDate(expiresAt.getDate() + 7);
          
          // Create or update the invite record
          const { error: inviteError } = await supabase
            .from('beta_invites')
            .upsert({
              email: application.email,
              token: token,
              created_at: new Date().toISOString(),
              expires_at: expiresAt.toISOString(),
              status: 'pending'
            });
          
          if (inviteError) {
            console.error('Error creating invite:', inviteError);
            toast({
              title: "Error",
              description: "Error creating invite",
              variant: "destructive"
            });
            setIsProcessing(false);
            return;
          }
          
          // Generate the invite URL
          inviteUrl = `${window.location.origin}/invite?token=${token}`;
          console.log('Invite URL generated:', inviteUrl);
        } else {
          console.log('User already exists, skipping invite generation');
        }
      }
      
      // Update the application status
      const { error: updateError } = await supabase
        .from('beta_applications')
        .update({ status: newStatus })
        .eq('id', application.id);
      
      if (updateError) {
        console.error('Error updating application:', updateError);
        toast({
          title: "Error",
          description: "Error updating application",
          variant: "destructive"
        });
        setIsProcessing(false);
        return;
      }
      
      // If the application is approved, send an email
      if (newStatus === 'approved') {
        try {
          // Send email using beta-approval-email function which is better configured for Zoho
          const { error } = await supabase.functions.invoke('beta-approval-email', {
            body: {
              email: application.email,
              name: `${application.first_name} ${application.last_name}`,
              inviteUrl: inviteUrl
            }
          });
          
          if (error) {
            console.error('Error invoking beta-approval-email function:', error);
            // Even if email fails, the status has been updated, so just show a warning
            toast({
              title: "Warning",
              description: "Application approved, but failed to send email notification",
              variant: "destructive"
            });
          } else {
            toast({
              title: "Success",
              description: `Application ${newStatus} and email notification sent`
            });
          }
        } catch (emailError) {
          console.error('Error sending approval email:', emailError);
          // Even if email fails, the status has been updated, so just show a warning
          toast({
            title: "Warning",
            description: "Application approved, but failed to send email notification",
            variant: "destructive"
          });
        }
      } else {
        toast({
          title: "Success",
          description: `Application ${newStatus}`
        });
      }
      
      // Refresh the list of applications
      fetchApplications();
    } catch (error) {
      console.error('Error updating application:', error);
      toast({
        title: "Error",
        description: "Error updating application",
        variant: "destructive"
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const getStatusColor = (status: BetaApplication['status']) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'waitlist':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };

  // Debug function to test email sending directly
  const handleTestEmail = async () => {
    setIsTestEmailLoading(true);
    try {
      const testEmail = prompt("Enter email address for test:");
      if (!testEmail) {
        setIsTestEmailLoading(false);
        return;
      }
      
      const testName = prompt("Enter name for test:", "Test User");
      const useInviteUrl = confirm("Include an invite URL?");
      let inviteUrl = null;
      
      if (useInviteUrl) {
        // Generate a test token
        const token = typeof crypto !== 'undefined' && crypto.randomUUID 
          ? crypto.randomUUID() 
          : Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        
        inviteUrl = `${window.location.origin}/invite?token=${token}`;
      }
      
      // Send test email
      const { error } = await supabase.functions.invoke('beta-approval-email', {
        body: {
          email: testEmail,
          name: testName || "Test User",
          inviteUrl: inviteUrl
        }
      });
      
      if (error) {
        console.error('Error sending test email:', error);
        toast({
          title: "Error",
          description: "Failed to send test email",
          variant: "destructive"
        });
      } else {
        toast({
          title: "Success", 
          description: "Test email sent successfully!"
        });
      }
    } catch (error) {
      console.error('Error in test email function:', error);
      toast({
        title: "Error",
        description: "Error sending test email",
        variant: "destructive"
      });
    } finally {
      setIsTestEmailLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-semibold">Beta Applications</h3>
          <div className="text-sm text-gray-500">
            {approvedCount} / {TOTAL_BETA_SPOTS} spots filled
          </div>
        </div>
        <div className="mb-4 flex justify-between items-center">
          <Button onClick={fetchApplications} variant="outline" disabled={loading || isProcessing}>
            {(loading || isProcessing) ? <Loader className="h-4 w-4 mr-2" /> : <RefreshCw className="h-4 w-4 mr-2" />}
            Refresh
          </Button>
          
          <Button onClick={handleTestEmail} variant="outline" disabled={isTestEmailLoading} className="bg-blue-50 text-blue-600 hover:bg-blue-100">
            {isTestEmailLoading ? <Loader className="h-4 w-4 mr-2" /> : <Mail className="h-4 w-4 mr-2" />}
            Test Email
          </Button>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Specialty</TableHead>
              <TableHead>LinkedIn</TableHead>
              <TableHead>Connections</TableHead>
              <TableHead>Applied</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {applications.map((application) => (
              <TableRow key={application.id}>
                <TableCell>{application.full_name}</TableCell>
                <TableCell>{application.email}</TableCell>
                <TableCell>{application.specialty}</TableCell>
                <TableCell>
                  <a 
                    href={application.linkedin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline"
                  >
                    View Profile
                  </a>
                </TableCell>
                <TableCell>{application.linkedin_connections}</TableCell>
                <TableCell>{new Date(application.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(application.status)}`}>
                    {application.status}
                  </span>
                </TableCell>
                <TableCell>
                  {application.status === 'pending' && (
                    <div className="flex gap-2">
                      <Button
                        size="sm"
                        variant="outline"
                        className="bg-green-50 text-green-600 hover:bg-green-100"
                        onClick={() => handleUpdateBetaApplication(application, 'approved')}
                        disabled={approvedCount >= TOTAL_BETA_SPOTS}
                      >
                        Approve
                      </Button>
                      <Button
                        size="sm"
                        variant="outline"
                        className="bg-blue-50 text-blue-600 hover:bg-blue-100"
                        onClick={() => handleUpdateBetaApplication(application, 'waitlist')}
                      >
                        Waitlist
                      </Button>
                      <Button
                        size="sm"
                        variant="outline"
                        className="bg-red-50 text-red-600 hover:bg-red-100"
                        onClick={() => handleUpdateBetaApplication(application, 'rejected')}
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default function Admin() {
  const [stats, setStats] = useState<Statistics | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showUserTable, setShowUserTable] = useState(false);
  const [showSubscribedUserTable, setShowSubscribedUserTable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkAdminStatus();
  }, []);

  const checkAdminStatus = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      navigate('/');
      return;
    }

    const { data: adminUser } = await supabase
      .from('admin_users')
      .select('user_id')
      .eq('user_id', user.id)
      .single();

    if (!adminUser) {
      navigate('/');
      return;
    }

    setIsAdmin(true);
    fetchStatistics();
  };

  const fetchStatistics = async () => {
    try {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const thirtyDaysAgoStr = thirtyDaysAgo.toISOString();

      // Basic counts
      const [
        { count: totalUsers },
        { count: subscribedUsers },
        { count: totalPosts },
        { count: totalContentPlans },
        { count: totalHookSuggestions },
        { count: totalProfileReviews }
      ] = await Promise.all([
        supabase.from('profiles').select('*', { count: 'exact', head: true }),
        supabase.from('profiles').select('*', { count: 'exact', head: true }).eq('is_subscribed', true),
        supabase.from('posts').select('*', { count: 'exact', head: true }),
        supabase.from('content_plans').select('*', { count: 'exact', head: true }),
        supabase.from('hook_suggestions').select('*', { count: 'exact', head: true }),
        supabase.from('profile_reviews').select('*', { count: 'exact', head: true })
      ]);

      // Recent data
      const [
        { data: recentUsers },
        { data: recentPosts },
        { data: recentPlans },
        { data: recentFeedback },
        { data: subscriptions },
        { count: newSignups },
        { count: newSubscriptions }
      ] = await Promise.all([
        // Recent users
        supabase.from('profiles')
          .select('email, created_at, is_subscribed')
          .order('created_at', { ascending: false })
          .limit(5),
        // Recent posts
        supabase.from('posts')
          .select('id, title, type, content, created_at, status, user_id')
          .order('created_at', { ascending: false })
          .limit(5),
        // Recent plans
        supabase.from('content_plans')
          .select('id, name, created_at, posts_per_week, status, user_id')
          .order('created_at', { ascending: false })
          .limit(5),
        // Recent feedback
        supabase.from('feedback')
          .select('id, content, created_at, user_id, status')
          .order('created_at', { ascending: false })
          .limit(5),
        // Subscriptions
        supabase.from('subscriptions')
          .select('status, created_at'),
        // New signups
        supabase.from('profiles')
          .select('*', { count: 'exact', head: true })
          .gte('created_at', thirtyDaysAgoStr),
        // New subscriptions
        supabase.from('subscriptions')
          .select('*', { count: 'exact', head: true })
          .eq('status', 'active')
          .gte('created_at', thirtyDaysAgoStr)
      ]);

      // Get emails for the recent items
      const userIds = new Set([
        ...(recentPosts || []).map(p => p.user_id),
        ...(recentPlans || []).map(p => p.user_id),
        ...(recentFeedback || []).map(f => f.user_id)
      ]);

      const { data: userEmails } = await supabase
        .from('profiles')
        .select('id, email')
        .in('id', Array.from(userIds));

      const emailMap = (userEmails || []).reduce((acc, user) => {
        acc[user.id] = user.email;
        return acc;
      }, {} as Record<string, string>);

      // Process the data
      const processedFeedback = (recentFeedback || []).map((f) => ({
        ...f,
        profiles: {
          email: emailMap[f.user_id] || 'Unknown'
        }
      }));

      const processedPosts = (recentPosts || []).map((p) => ({
        ...p,
        profiles: {
          email: emailMap[p.user_id] || 'Unknown'
        }
      }));

      const processedPlans = (recentPlans || []).map((p) => ({
        ...p,
        profiles: {
          email: emailMap[p.user_id] || 'Unknown'
        }
      }));

      // Calculate subscription stats
      const activeSubscriptionCount = subscriptions?.filter(s => s.status === 'active').length || 0;
      const canceledSubscriptionCount = subscriptions?.filter(s => s.status === 'canceled').length || 0;
      const subscriptionAges = subscriptions?.map(s => 
        new Date().getTime() - new Date(s.created_at).getTime()
      ) || [];
      const averageSubscriptionAge = subscriptionAges.length 
        ? subscriptionAges.reduce((a, b) => a + b, 0) / subscriptionAges.length / (24 * 60 * 60 * 1000)
        : 0;

      const subscriptionStats = {
        activeSubscriptions: activeSubscriptionCount,
        canceledSubscriptions: canceledSubscriptionCount,
        conversionRate: ((activeSubscriptionCount / (totalUsers || 1)) * 100),
        averageSubscriptionAge: Math.round(averageSubscriptionAge),
        newSubscriptionsLast30Days: newSubscriptions || 0
      };

      // Calculate post type distribution
      const postTypeDistribution = (recentPosts || []).reduce((acc: Record<string, number>, post) => {
        acc[post.type] = (acc[post.type] || 0) + 1;
        return acc;
      }, {});

      // Calculate user stats
      const averagePostLength = (recentPosts || []).reduce((acc, post) => 
        acc + (post.content?.length || 0), 0) / (recentPosts?.length || 1);

      const userStats = {
        totalPosts: totalPosts || 0,
        averagePostsPerUser: totalPosts ? (totalPosts / (totalUsers || 1)) : 0,
        newSignupsLast30Days: newSignups || 0,
        averagePostLength: Math.round(averagePostLength)
      };

      setStats({
        totalUsers: totalUsers || 0,
        subscribedUsers: subscribedUsers || 0,
        totalPosts: totalPosts || 0,
        totalContentPlans: totalContentPlans || 0,
        totalHookSuggestions: totalHookSuggestions || 0,
        totalProfileReviews: totalProfileReviews || 0,
        recentFeedback: processedFeedback,
        recentUsers: recentUsers || [],
        recentPosts: processedPosts,
        recentPlans: processedPlans,
        subscriptionStats,
        postTypeDistribution,
        userStats
      });
    } catch (error) {
      console.error('Error fetching statistics:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isAdmin) {
    return null;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Admin Dashboard</h1>
      
      <Tabs defaultValue="overview" className="space-y-4">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="users">Users</TabsTrigger>
          <TabsTrigger value="content">Content</TabsTrigger>
          <TabsTrigger value="feedback">Feedback</TabsTrigger>
          <TabsTrigger value="beta">Beta Program</TabsTrigger>
          <TabsTrigger value="llm-settings">LLM Settings</TabsTrigger>
        </TabsList>

        <TabsContent value="overview">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <Card 
              className="p-4 cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={() => setShowUserTable(true)}
            >
              <h3 className="text-gray-500 text-sm mb-2">Total Users</h3>
              <p className="text-2xl font-semibold">{stats?.totalUsers}</p>
            </Card>

            <Card 
              className="p-4 cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={() => setShowSubscribedUserTable(true)}
            >
              <h3 className="text-gray-500 text-sm mb-2">Subscribed Users</h3>
              <p className="text-2xl font-semibold">{stats?.subscribedUsers}</p>
              <p className="text-sm text-gray-500">
                ({((stats?.subscribedUsers || 0) / (stats?.totalUsers || 1) * 100).toFixed(1)}% conversion)
              </p>
            </Card>

            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-2">Total Posts</h3>
              <p className="text-2xl font-semibold">{stats?.totalPosts}</p>
              <p className="text-sm text-gray-500">
                ({((stats?.totalPosts || 0) / (stats?.totalUsers || 1)).toFixed(1)} posts per user)
              </p>
            </Card>

            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-2">Content Plans</h3>
              <p className="text-2xl font-semibold">{stats?.totalContentPlans}</p>
            </Card>

            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-2">Hook Suggestions</h3>
              <p className="text-2xl font-semibold">{stats?.totalHookSuggestions}</p>
            </Card>

            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-2">Profile Reviews</h3>
              <p className="text-2xl font-semibold">{stats?.totalProfileReviews}</p>
            </Card>

            <Card className="p-4 col-span-full">
              <h3 className="text-gray-500 text-sm mb-4">Growth Metrics (Last 30 Days)</h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div>
                  <p className="text-2xl font-semibold">{stats?.userStats.newSignupsLast30Days}</p>
                  <p className="text-sm text-gray-500">New Signups</p>
                </div>
                <div>
                  <p className="text-2xl font-semibold">{stats?.subscriptionStats.newSubscriptionsLast30Days}</p>
                  <p className="text-sm text-gray-500">New Subscriptions</p>
                </div>
                <div>
                  <p className="text-2xl font-semibold">{stats?.userStats.averagePostLength}</p>
                  <p className="text-sm text-gray-500">Avg Post Length</p>
                </div>
                <div>
                  <p className="text-2xl font-semibold">{stats?.subscriptionStats.averageSubscriptionAge}</p>
                  <p className="text-sm text-gray-500">Avg Sub Age (days)</p>
                </div>
              </div>
            </Card>
          </div>
        </TabsContent>

        <TabsContent value="users">
          <div className="space-y-4">
            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-4">Recent Users</h3>
              <div className="space-y-4">
                {stats?.recentUsers.map((user, index) => (
                  <div key={index} className="border-b pb-3 last:border-b-0">
                    <p className="font-medium">{user.email}</p>
                    <p className="text-sm text-gray-500">
                      Joined {new Date(user.created_at).toLocaleDateString()} • 
                      {user.is_subscribed ? ' Pro User' : ' Free User'}
                    </p>
                  </div>
                ))}
              </div>
            </Card>

            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-4">Subscription Stats</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-2xl font-semibold">{stats?.subscriptionStats.activeSubscriptions}</p>
                  <p className="text-sm text-gray-500">Active Subscriptions</p>
                </div>
                <div>
                  <p className="text-2xl font-semibold">{stats?.subscriptionStats.canceledSubscriptions}</p>
                  <p className="text-sm text-gray-500">Canceled Subscriptions</p>
                </div>
              </div>
            </Card>
          </div>
        </TabsContent>

        <TabsContent value="content">
          <div className="space-y-4">
            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-4">Recent Posts</h3>
              <div className="space-y-4">
                {stats?.recentPosts.map((post, index) => (
                  <div key={index} className="border-b pb-3 last:border-b-0">
                    <p className="font-medium">{post.title}</p>
                    <p className="text-sm text-gray-500">
                      {post.type} • {new Date(post.created_at).toLocaleDateString()} • {post.profiles.email}
                    </p>
                  </div>
                ))}
              </div>
            </Card>

            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-4">Post Type Distribution</h3>
              <div className="space-y-2">
                {Object.entries(stats?.postTypeDistribution || {}).map(([type, count]) => (
                  <div key={type} className="flex justify-between items-center">
                    <span className="text-gray-700">{type}</span>
                    <span className="font-semibold">{count}</span>
                  </div>
                ))}
              </div>
            </Card>

            <Card className="p-4">
              <h3 className="text-gray-500 text-sm mb-4">Recent Content Plans</h3>
              <div className="space-y-4">
                {stats?.recentPlans.map((plan, index) => (
                  <div key={index} className="border-b pb-3 last:border-b-0">
                    <p className="font-medium">{plan.name}</p>
                    <p className="text-sm text-gray-500">
                      {plan.posts_per_week} posts/week • Created {new Date(plan.created_at).toLocaleDateString()} • {plan.profiles.email}
                    </p>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </TabsContent>

        <TabsContent value="feedback">
          <Card className="p-4">
            <h3 className="text-gray-500 text-sm mb-4">Recent Feedback</h3>
            <div className="space-y-4">
              {stats?.recentFeedback.map((feedback, index) => (
                <div key={index} className="border-b pb-3 last:border-b-0">
                  <div className="flex justify-between items-start mb-2">
                    <p className="font-medium">{feedback.profiles.email}</p>
                    <p className="text-sm text-gray-500">
                      {new Date(feedback.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  <p className="text-gray-700">{feedback.content}</p>
                </div>
              ))}
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="beta">
          <BetaApplicationsTab />
        </TabsContent>

        <TabsContent value="llm-settings">
          <LLMSettingsTab />
        </TabsContent>
      </Tabs>

      <UserTableDialog
        open={showUserTable}
        onOpenChange={setShowUserTable}
        title="All Users"
        showSubscribedOnly={false}
      />

      <UserTableDialog
        open={showSubscribedUserTable}
        onOpenChange={setShowSubscribedUserTable}
        title="Subscribed Users"
        showSubscribedOnly={true}
      />
    </div>
  );
} 