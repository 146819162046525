import { makeAIRequest, getCurrentModel } from '@/services/deepseek';
import type { UserProfile, IdealClientProfile } from '@/types/profile';

interface Theme {
  id: string;
  label: string;
}

export async function generateThemes(
  context: string,
  userProfile?: UserProfile,
  idealClientProfile?: IdealClientProfile
): Promise<Theme[]> {
  try {
    const prompt = `
      Generate personalized content themes based on the following information:

      Content Creator Profile:
      ${userProfile?.description || 'No profile information available'}

      Target Audience:
      ${idealClientProfile?.description || 'No audience information available'}

      Theme Context:
      ${context || 'No additional context provided'}

      Generate themes that:
      1. Reflect the content creator's expertise and professional background
      2. Address the target audience's challenges and goals
      3. Leverage common interests between the creator and audience
      4. Consider specific industry and demographic contexts
      5. Align with both the creator's and audience's goals
      6. Directly incorporate elements from the provided theme context
      7. They should be a max of 50 characters long and the themes should be open enough to allow for multiple posts around the theme.
      For Example: A theme for the week might be "Growth Hacking for SaaS Startups", "Starting to Learn English", "How to Make a Good First Impression", "Current Trends in Healthcare" aka they should be open and broad.

      Return exactly 8 unique, engaging themes in the following JSON format:
      {
        "themes": [
          { "id": "1", "label": "Theme 1" },
          { "id": "2", "label": "Theme 2" },
          ...
        ]
      }
      
      Each theme should be specific, actionable, and directly relate to the provided context.
    `;

    const systemPrompt = 'You are an expert content strategist specializing in creating engaging, professional content themes. Your themes should be specific, actionable, and tailored to the user\'s profile and their target audience. Always return the response in the exact JSON format specified in the prompt.';
    
    const content = await makeAIRequest(prompt, systemPrompt, getCurrentModel());
    if (!content) {
      throw new Error('Empty response from API');
    }

    // Clean and parse the JSON response
    const cleanedContent = content.replace(/```json\s*|\s*```/g, '').trim();
    const parsedContent = JSON.parse(cleanedContent);

    if (!parsedContent || !Array.isArray(parsedContent.themes)) {
      console.error('Invalid response structure:', parsedContent);
      throw new Error('Invalid response format from API');
    }

    // Validate each theme has required properties
    const validThemes = parsedContent.themes.filter((theme: unknown): theme is Theme => 
      theme !== null &&
      typeof theme === 'object' &&
      'id' in theme &&
      'label' in theme &&
      typeof (theme as Theme).id === 'string' && 
      typeof (theme as Theme).label === 'string' &&
      (theme as Theme).label.length <= 50
    );

    if (validThemes.length === 0) {
      throw new Error('No valid themes in API response');
    }

    return validThemes;
  } catch (error) {
    console.error('Error generating themes:', error);
    // Return default themes as fallback
    return [
      { id: '1', label: 'Professional Development & Career Growth' },
      { id: '2', label: 'Industry Trends & Innovation' },
      { id: '3', label: 'Leadership & Management Skills' },
      { id: '4', label: 'Business Strategy & Success' },
      { id: '5', label: 'Personal Branding & Networking' }
    ];
  }
} 